import React, { useState, useEffect } from 'react';
import { auth, database } from '../firebase'; // Adjust the path as necessary
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, TwitterAuthProvider, setPersistence, browserLocalPersistence } from "firebase/auth";
import { ref, get } from "firebase/database";
import { useNavigate } from 'react-router-dom';
import './Login.css';
import continueIcon from '../assets/continue-icon.svg';
import googleIcon from '../assets/images/google-logo.svg';
import xIcon from '../assets/images/x-logo.svg';
import dividerVector from '../assets/images/divider-vector.svg'; // Import the divider vector
import eyeVector from '../assets/images/eye-vector.svg'; // Import the eye vector

const googleProvider = new GoogleAuthProvider();
const twitterProvider = new TwitterAuthProvider();

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        // Check if the app is embedded in an iframe
        if (window.self === window.top) {
          const userRef = ref(database, 'users/' + user.uid);
          const snapshot = await get(userRef);
          const userData = snapshot.val();

          if (userData) {
            if (userData.signedUp) {
              navigate(`/creator-page/${user.uid}`);
            } else {
              if (userData.userType === 'creator') {
                navigate('/creator-typeform', { state: { userId: user.uid } });
              } else if (userData.userType === 'studio') {
                navigate('/studio-typeform', { state: { userId: user.uid } });
              }
            }
          }
        }
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      // Check if the app is embedded in an iframe
      if (window.self !== window.top) {
        // Do not set persistence if in an iframe
      } else {
        await setPersistence(auth, browserLocalPersistence);
      }
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Fetch user data from Firebase
      const userRef = ref(database, 'users/' + user.uid);
      const snapshot = await get(userRef);
      const userData = snapshot.val();

      if (userData) {
        console.log('User logged in successfully:', user);
        // Check the signedUp property
        if (userData.signedUp) {
          // Navigate to the appropriate profile page based on userType
          if (userData.userType === 'creator') {
            navigate(`/creator-page/${user.uid}`);
          } else if (userData.userType === 'studio') {
            navigate(`/studio-page/${user.uid}`);
          } else {
            alert('User type is not recognized.');
          }
        } else {
          // Navigate to the appropriate typeform based on userType
          if (userData.userType === 'creator') {
            navigate('/creator-typeform', { state: { userId: user.uid } });
          } else if (userData.userType === 'studio') {
            navigate('/studio-typeform', { state: { userId: user.uid } });
          } else {
            alert('User type is not recognized.');
          }
        }
      } else {
        console.error('No data found for user:', user.uid);
        alert('User data not found.');
      }
    } catch (error) {
      console.error('Error during login:', error);
      alert('Login failed: ' + error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      // Check if the app is embedded in an iframe
      if (window.self !== window.top) {
        // Do not set persistence if in an iframe
      } else {
        await setPersistence(auth, browserLocalPersistence);
      }
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      console.log('Google sign-in successful:', user);

      // Fetch user data from Firebase
      const userRef = ref(database, 'users/' + user.uid);
      const snapshot = await get(userRef);
      const userData = snapshot.val();

      if (userData) {
        // Check the signedUp property
        if (userData.signedUp) {
          // Navigate to the appropriate profile page based on userType
          if (userData.userType === 'creator') {
            navigate(`/creator-page/${user.uid}`);
          } else if (userData.userType === 'studio') {
            navigate(`/studio-page/${user.uid}`);
          } else {
            alert('User type is not recognized.');
          }
        } else {
          // Navigate to the appropriate typeform based on userType
          if (userData.userType === 'creator') {
            navigate('/creator-typeform', { state: { userId: user.uid } });
          } else if (userData.userType === 'studio') {
            navigate('/studio-typeform', { state: { userId: user.uid } });
          } else {
            alert('User type is not recognized.');
          }
        }
      } else {
        console.error('No data found for user:', user.uid);
        alert('User data not found.');
      }
    } catch (error) {
      console.error('Error during Google sign-in:', error);
      alert('Google sign-in failed: ' + error.message);
    }
  };

  const handleTwitterSignIn = async () => {
    try {
      // Check if the app is embedded in an iframe
      if (window.self !== window.top) {
        // Do not set persistence if in an iframe
      } else {
        await setPersistence(auth, browserLocalPersistence);
      }
      const result = await signInWithPopup(auth, twitterProvider);
      const user = result.user;
      console.log('Twitter sign-in successful:', user);

      // Fetch user data from Firebase
      const userRef = ref(database, 'users/' + user.uid);
      const snapshot = await get(userRef);
      const userData = snapshot.val();

      if (userData) {
        // Check the signedUp property
        if (userData.signedUp) {
          // Navigate to the appropriate profile page based on userType
          if (userData.userType === 'creator') {
            navigate(`/creator-page/${user.uid}`);
          } else if (userData.userType === 'studio') {
            navigate(`/studio-page/${user.uid}`);
          } else {
            alert('User type is not recognized.');
          }
        } else {
          // Navigate to the appropriate typeform based on userType
          if (userData.userType === 'creator') {
            navigate('/creator-typeform', { state: { userId: user.uid } });
          } else if (userData.userType === 'studio') {
            navigate('/studio-typeform', { state: { userId: user.uid } });
          } else {
            alert('User type is not recognized.');
          }
        }
      } else {
        console.error('No data found for user:', user.uid);
        alert('User data not found.');
      }
    } catch (error) {
      console.error('Error during Twitter sign-in:', error);
      alert('Twitter sign-in failed: ' + error.message);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login-profile">
      <h2>Sign In</h2>
      <p>Don't have an account? <a href="/">Sign Up Here</a></p>
      <form className='login-form' onSubmit={handleLogin}>
        <input
          type="email"
          className="login-input"
          placeholder="EMAIL"
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <div className="password-container">
          <input
            type={showPassword ? "text" : "password"}
            className="login-input"
            placeholder="PASSWORD"
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <p className='forgot-password'>
          <a href="/password-reset" className="forgot-password-link">Forgot Password?</a>
          </p>
          <img src={dividerVector} alt="Divider" className="divider-vector" />
          <img
            src={eyeVector}
            alt="Toggle Password Visibility"
            className="eye-vector"
            onClick={togglePasswordVisibility}
          />
        </div>
        <button 
          className="creator-continue-button" 
          type="submit"
        >
          <div className="text-wrapper">SIGN IN</div>
          <img src={continueIcon} alt="Continue" className="icon" />
        </button>
      </form>
      <p className='socials-text'>
        Or continue with
      </p>
      <div className='login-social-buttons'>
        <button className='login-social-google' onClick={handleGoogleSignIn}>
          <img src={googleIcon} alt="Google" />
        </button>
        <button className='login-social-x' onClick={handleTwitterSignIn}>
          <img src={xIcon} alt="X" />
        </button>
      </div>
    </div>
  );
};

export default Login;
