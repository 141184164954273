import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { auth, database } from './firebase'; // Adjust the path as necessary
import SignUpSelection from './components/SignUpSelection';
import CreatorProfile from './components/CreatorProfile';
import StudioProfile from './components/StudioProfile';
import Header from './components/Header'; // Import the standard header
import CreatorTypeform from './components/CreatorTypeform';
import StudioTypeform from './components/StudioTypeform'; // Import the new component
import Login from './components/Login';
import CreatorPage from './components/CreatorPage'; // Import the new component
import StudioPage from './components/StudioPage'; // Import your StudioPage component
import HeaderMobile from './components/HeaderMobile'; // Import the mobile Header component
import Footer from './components/Footer'; // Import the Footer component
import { onAuthStateChanged } from "firebase/auth";
import { ref, get } from "firebase/database";
import PasswordResetForm from './components/PasswordReset';
import PasswordSet from './components/PasswordSet';

const App = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const isTypeformRoute = location.pathname === '/creator-typeform' || location.pathname === '/studio-typeform';
	const isMobile = window.innerWidth < 810; // Check if the screen width is less than 810px

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, async (user) => {
			console.log('Auth state changed:', user); // Log the user object

			if (user) {
				// Check if the app is embedded in an iframe
				if (window.self === window.top) {
					const userRef = ref(database, 'users/' + user.uid);
					const snapshot = await get(userRef);
					const userData = snapshot.val();
					console.log('User data fetched:', userData); // Log the fetched user data

					if (userData) {
						if (userData.signedUp) {
							console.log('Navigating to creator page:', user.uid); // Log navigation
							navigate(`/creator-page/${user.uid}`); // Redirect to creator page
						} else {
							if (userData.userType === 'creator') {
								console.log('Navigating to creator typeform:', user.uid); // Log navigation
								navigate('/creator-typeform', { state: { userId: user.uid } });
							} else if (userData.userType === 'studio') {
								console.log('Navigating to studio typeform:', user.uid); // Log navigation
								navigate('/studio-typeform', { state: { userId: user.uid } });
							}
						}
					}
				}
			} else {
				console.log('User not authenticated, no automatic navigation'); // Log no navigation
				// Do not navigate to login automatically
			}
		});

		return () => unsubscribe(); // Cleanup subscription on unmount
	}, [navigate]);

	return (
		<div className="app">
			{!isTypeformRoute && (isMobile ? <HeaderMobile /> : <Header />)} {/* Render mobile or standard header based on screen size, except on typeform routes */}
			<Routes>
				<Route path="/" element={<SignUpSelection />} />
				<Route path="/signup-creator" element={<CreatorProfile />} />
				<Route path="/signup-studio" element={<StudioProfile />} />
				<Route path="/creator-typeform" element={<CreatorTypeform />} />
				<Route path="/studio-typeform" element={<StudioTypeform />} />
				<Route path="/login" element={<Login />} />
				<Route path="/studio-page/:userId" element={<StudioPage />} />
				<Route path="/creator-page/:userId" element={<CreatorPage />} />
				<Route path="/password-reset" element={<PasswordResetForm />} />
				<Route path="/password-set" element={<PasswordSet />} />
			</Routes>
			{!isTypeformRoute && <Footer />} {/* Render Footer only if not on typeform routes */}
		</div>
	);
};

export default App;
