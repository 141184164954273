import React, { useState, useEffect } from 'react';
import { auth, database } from '../firebase'; // Import Firebase auth and database
import { createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, TwitterAuthProvider, setPersistence, browserLocalPersistence } from "firebase/auth"; // Import TwitterAuthProvider
import { ref, set, get } from "firebase/database";
import { useNavigate } from 'react-router-dom';
import continueIcon from '../assets/continue-icon.svg';
import googleIcon from '../assets/images/google-logo.svg';
import xIcon from '../assets/images/x-logo.svg';
import '../components/CreatorProfile.css';
import Modal from './Modal'; // Import the Modal component
import dividerVector from '../assets/images/divider-vector.svg'; // Import the divider vector
import eyeVector from '../assets/images/eye-vector.svg'; // Import the eye vector

const googleProvider = new GoogleAuthProvider();
const twitterProvider = new TwitterAuthProvider(); // Create Twitter provider instance

const StudioProfile = () => {

  const [studioName, setStudioName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // State for error message
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userRef = ref(database, 'users/' + user.uid);
        const snapshot = await get(userRef);
        const userData = snapshot.val();

        if (userData) {
          if (userData.signedUp) {
            navigate(`/studio-page/${user.uid}`);
          } else {
            navigate('/studio-typeform', { state: { userId: user.uid } });
          }
        }
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const validatePassword = (password) => {
    const specialCharacterPattern = /[!@#$%^&*(),.?":{}|<>]/; // Regex for special characters
    return password.length >= 8 && specialCharacterPattern.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Check for empty fields
    if (!studioName || !email || !password || !confirmPassword) {
      setErrorMessage("Please fill all fields."); // Set error message
      setShowModal(true); // Show modal
      return;
    }

    if (!validatePassword(password)) {
      setErrorMessage("Password must be at least 8 characters long and include a special character."); // Set error message
      setShowModal(true); // Show modal
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match."); // Set error message
      setShowModal(true); // Show modal
      return;
    }
    try {
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Store additional user data in Realtime Database
      await set(ref(database, 'users/' + user.uid), {
        studioName,
        email,
        userType: 'studio', // Store user type
        signedUp: false, // Set signedUp to false
      });

      console.log('Studio profile submitted successfully:', user);
      navigate('/studio-typeform', { state: { userId: user.uid } }); // Update this line to navigate to StudioTypeform with user ID
    } catch (error) {
      if (error.code === 'auth/invalid-email') {
        setErrorMessage("Please enter a valid email."); // Custom error message for invalid email
      } else if (error.code === 'auth/email-already-in-use') {
        setErrorMessage("This account already exists! Sign In to continue."); // Custom error message for email already in use
      } else {
        setErrorMessage('Sign-up failed, please try again.'); // Default error message
      }
      setShowModal(true); // Show modal
    }
  };

  const handleSignIn = () => {
    navigate('/login'); // Navigate to the login page
  };

  const handleGoogleSignIn = async () => {
    try {
      // Set persistence to local
      await setPersistence(auth, browserLocalPersistence);
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      console.log('Google sign-in successful:', user);

      // Store user data in Realtime Database with signedUp set to false
      await set(ref(database, 'users/' + user.uid), {
        name: user.displayName,
        email: user.email,
        userType: 'studio', // or 'creator' based on your logic
        signedUp: false, // Set signedUp to false
      });

      // Navigate to the appropriate page after sign-in
      navigate('/studio-typeform', { state: { userId: user.uid } });
    } catch (error) {
      setErrorMessage('Google sign-in failed, please try again.'); // Set error message
      setShowModal(true); // Show modal
    }
  };

  const handleTwitterSignIn = async () => {
    try {
      // Set persistence to local
      await setPersistence(auth, browserLocalPersistence);
      const result = await signInWithPopup(auth, twitterProvider);
      const user = result.user;
      console.log('Twitter sign-in successful:', user);

      // Store user data in Realtime Database with signedUp set to false
      await set(ref(database, 'users/' + user.uid), {
        name: user.displayName,
        email: user.email,
        userType: 'studio', // or 'creator' based on your logic
        signedUp: false, // Set signedUp to false
      });

      // Navigate to the appropriate page after sign-in
      navigate('/studio-typeform', { state: { userId: user.uid } });
    } catch (error) {
      setErrorMessage('Twitter sign-in failed, please try again.'); // Set error message
      setShowModal(true); // Show modal
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="creator-profile">
      <h2>Studio Sign Up</h2>
      <p>Already have an account? <a href="/login">Sign In Here</a></p>
      <form className='form' onSubmit={handleSubmit}>

        <input
          type="text"
          className="input"
          placeholder="STUDIO NAME"
          value={studioName}
          onChange={(e) => setStudioName(e.target.value)}

          required
        />
        <input
          type="email"
          className="input"
          placeholder="EMAIL"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <div className="password-container">
          <input
            type={showPassword ? "text" : "password"}
            className="input"
            placeholder="PASSWORD"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <img src={dividerVector} alt="Divider" className="divider-vector" />
          <img
            src={eyeVector}
            alt="Toggle Password Visibility"
            className="eye-vector"
            onClick={togglePasswordVisibility}
          />
        </div>
        <div className="password-container">
          <input
            type={showConfirmPassword ? "text" : "password"}
            className="input"
            placeholder="CONFIRM PASSWORD"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <img src={dividerVector} alt="Divider" className="divider-vector" />
          <img
            src={eyeVector}
            alt="Toggle Confirm Password Visibility"
            className="eye-vector"
            onClick={toggleConfirmPasswordVisibility}
          />
        </div>
        <button 
          className="creator-continue-button" 
          onClick={handleSubmit} 
        >
          <div className="text-wrapper">SIGN UP</div>
          <img src={continueIcon} alt="Continue" className="icon" /> {/* Use the SVG icon */}
        </button>
      </form>
      <p className='socials-text'>
        Or continue with
      </p>
      <div className='social-buttons'>
        <button className='social-google' onClick={handleGoogleSignIn}>
          <img src={googleIcon} alt="Google" />
        </button>
        <button className='social-x' onClick={handleTwitterSignIn}>
          <img src={xIcon} alt="X" />
        </button>
      </div>
      <Modal 
        isOpen={showModal} 
        onClose={() => setShowModal(false)} 
        message={errorMessage} 
        onSignIn={handleSignIn} // Pass the sign-in handler
      />
    </div>
  );
};

export default StudioProfile;