import React, { useEffect, useState } from 'react'; // Import useState and useEffect
import { useParams, useNavigate } from 'react-router-dom';
import { ref, onValue, update } from "firebase/database"; // Import Firebase functions
import { database, auth } from '../firebase'; // Import your Firebase database
import ClipLoader from 'react-spinners/ClipLoader'; // Import the spinner component
import { signOut } from 'firebase/auth';

const CreatorPage = () => {
  const { userId } = useParams(); // Get userId from URL parameters
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state
  const navigate = useNavigate();

  useEffect(() => {
    if (window.self !== window.top) {
      window.top.location.href = window.location.href;
    }
  }, []);

  useEffect(() => {
    const userRef = ref(database, 'users/' + userId);
    const unsubscribe = onValue(userRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setUserData(data);
        // Update signedUp property to true
        update(ref(database, 'users/' + userId), { signedUp: true });
      } else {
        console.error('No data found for user:', userId);
      }
      setLoading(false); // Set loading to false after data is fetched
    });

    return () => unsubscribe(); // Clean up the listener on unmount
  }, [userId]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login'); // Redirect to login page after logout
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  if (loading) {
    return (
      <div className="loading-container">
        <ClipLoader size={50} color={"#123abc"} loading={loading} />
        <p>Loading...</p>
      </div>
    ); // Show loading state while fetching data
  }

  return (
    <div className="creator-page">
      <div className="profile-info">
        <h2>{userData.displayName}</h2>
        <p><strong>Email:</strong> {userData.email}</p>
        <p><strong>Bio:</strong> {userData.creatorBio}</p>
        <p><strong>Social Accounts:</strong> {userData.contentPlatforms}</p>
        <p><strong>Website:</strong> <a href={userData.studioWebsite} target="_blank" rel="noopener noreferrer">{userData.studioWebsite}</a></p>
        <p><strong>Game Title:</strong> {userData.gameTitle}</p>
        <p><strong>Team Details:</strong> {userData.teamDetails}</p>
        <p><strong>Featured Content:</strong> {userData.featureContent.split(',').map((link, index) => (
          <div key={index}>
            <a href={link.trim()} target="_blank" rel="noopener noreferrer">{link.trim()}</a>
          </div>
        ))}</p>
      </div>
      <button className="logout-button" onClick={handleLogout}>
        Logout
      </button>
    </div>
  );
};

export default CreatorPage;
